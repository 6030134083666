<template>
  <div id="home">
    <div class="row my-3">
      <div class="col-12 section-head">
        <h3 class="section-head-title ps-0 my-3">
          ¡Conoce el comportamiento de tus usuarios! <strong>Filtra</strong>,
          <strong>revisa</strong> los indicadores claves y
          <strong>descarga</strong> reportes ingresando el periodo de tu interés.
        </h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-4 mb-3">
        <div class="row">
          <div class="col">
            <v-select
              class="selvue-custom"
              :clearable="false"
              :options="years_dashboard"
              @input="searchUsability()"
              v-model="selectedYear"
              label="year"
              :placeholder="selectedYear">
              <span slot="no-options"></span>
            </v-select>
          </div>
          <div class="col">
            <v-select
              class="selvue-custom"
              :options="activeMonths()"
              @input="searchUsability()"
              v-model="selectedMonth"
              :clearable="false"
              :placeholder="selectedMonth">
              <span slot="no-options"></span>
            </v-select>           
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 mb-3">
        <div class="row">
          <div class="col-12 col-lg-3 col-xl-4 col-xxl-5 d-flex align-items-center justify-content-lg-end text-secondary">
            Descargar reporte:
          </div>
          <div class="col-10 col-lg-7 col-xl-6 col-xxl-6">

            <div class="row">
              <div class="col-6">
                <InputDate 
                  :max="range.date_end"
                  placeHolder="Inicio"
                  @dateEmit="captureDateStart"
                />
              </div>
              <div class="col-6">
                <InputDate 
                  :min="range.date_start" 
                  placeHolder="Término"
                  @dateEmit="captureDateEnd"
                />
              </div>
            </div>
            
          </div>
          <div class="col-2 col-xxl-1 d-flex">
            <button 
              :disabled="!activarDescargaExcel" 
              class="btn btn-custom btn-custom-color-blue border-round-50 flex-fill" 
              icon="download"
              v-on:click="getDataExcel()">
              <font-awesome-icon icon="download"/>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-lg-6 mb-5 mb-lg-0">

            <div class="row">
              <div class="col-12">
                <div class="card card-custom border-round-10 card-shadow mb-3">
                  <div class="card-body">
                    <h5 class="card-title">¡Hola! <strong>{{ usuario.primer_nombre }}</strong></h5>
                    <p class="card-text"> {{ intro_text }} </p>
                  </div>
                </div>
              </div>
            </div>

            <Loading 
              v-if="loading"
              pixeles="150px"
            />
            <template v-if="!loading">          
              <div class="row">
                <div class="col-12">
                  <div class="row row-cols-2 g-2 row-cols-xxl-4">
                    <div class="col">
                      <div class="card card-shadow border-round-10 bg-blue-light-2">
                        <div class="card-body text-blue text-center">
                          <strong class="h1-custom font-title-bold mb-0">{{ estadisticasUsabilidad.usuarios_plan.cantidad_usuarios }}</strong>
                          <p class="card-text">Plan contratado</p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card card-shadow border-round-10 bg-blue-light">
                        <div class="card-body text-blue text-center">
                          <strong class="h1-custom font-title-bold mb-0">{{ estadisticasUsabilidad.total_usuarios }}</strong>
                          <p class="card-text"> Registrados </p>
                        </div>
                      </div>                  
                    </div>
                    <div class="col">
                      <div class="card card-shadow border-round-10 bg-blue">
                        <div class="card-body text-white text-center">
                          <strong class="h1-custom font-title-bold mb-0">{{ estadisticasUsabilidad.total_activos }}</strong>
                          <p class="card-text"> Activos </p>
                        </div>
                      </div>                 
                    </div>
                    <div class="col">
                      <div class="card card-shadow border-round-10 bg-green">
                        <div class="card-body text-white text-center">
                          <strong class="h1-custom font-title-bold mb-0">{{ estadisticasUsabilidad.usabilidad+'%' }}</strong>
                          <p class="card-text">Usabilidad</p>
                        </div>
                      </div>                  
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="row mt-3">
                <div class="col-12">        
                  <GraphCardHome :usability="usability" :month="selectedMonth"/>
                </div>
              </div>
            </template>
          </div>

          <div class="col-12 col-lg-6">
            <div class="card card-custom card-shadow border-round-10">
              <div class="card-body">
                <h5 class="card-title"><strong>Ranking de módulos</strong></h5>
                <div class="list-group">
                  <div v-for="(modulo, index) in sortedFirstModulesByViews()" :key="index">
                    <a 
                      href="javascript:" 
                      @click="captureModulo(modulo)"
                      data-bs-toggle="modal" 
                      data-bs-target="#modal-home"
                      class="list-group-item list-group-item-action border-0"
                      :class="{ 'border-bottom': index !== sortedFirstModulesByViews().length - 1 }"
                    >
                        <div class="d-flex w-100 justify-content-between align-items-center">
                          <div class="d-flex align-items-center">
                            <div class="flex-shrink-0">
                              <img :src="modulo.imagen_url" width="60">
                            </div>
                            <div class="flex-grow-1 ms-3">
                              <h6 class="card-subtitle">{{ modulo.nombre_modulo }}</h6>
                              <p class="card-text">{{ modulo.visitas + " visitas" }}</p>
                            </div>
                          </div>
                          <a 
                            href="javascript:" 
                            class="icon-link color-main text-decoration-none"
                          >
                            Ver más
                          </a>
                        </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HomeModal :modulo="modulo_actual"/>
    </div>
  </div>
</template>

<script>
import HomeModal from "./HomeModal.vue";
import { mapActions, mapState } from "vuex";
import GraphCardHome from "./GraphCardHome.vue";
import "export-json-excel";
import { utils, writeFile } from 'xlsx';
import moment from "moment-timezone";

//FF
import Loading from "@/components/Loading.vue";
import InputDate from "@/components/forms/InputDate.vue"

export default {
  components: { 
      HomeModal, 
      GraphCardHome,

      //FF
      Loading,
      InputDate
    },

  data() {
    return {
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      // filtro periodo
      selectedFecha: "",
      selectedMonth: "",
      selectedYear: "",
      usuario: "",
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      id_grupo: this.$ls.get("user")?.grupo && typeof this.$ls.get("user")?.grupo[0]=== 'object' && this.$ls.get("user")?.grupo[0]!=null? this.$ls.get("user")?.grupo[0].id_grupo : "null",

      //FF
      loading: true,
      intro_text: "Aquí encontrarás información clave para definir acciones que potencien la usabilidad. Te recomendamos analizar métricas cada mes, \
        actualizar contenidos semanalmente y siempre enviar notificaciones push ante nuevas actualizaciones.",
      modulo_actual: {},
      range: {
        date_start: null,
        date_end: null,
        mask_start: null,
        mask_end: null
      },
      estadisticasUsabilidad: {
        total_activos: "",
        total_enlinea: "",
        total_usuarios: "",
        usabilidad: "",
        usuarios_plan: {
          id_plan: "",
          cantidad_usuarios: "",
          cantidad_pantallas: ""
        }
      }
    };
  },

  computed: {
    ...mapState("adminHomeModule", [
      "generalStatistics",
      "usability",
      "dataExcel",
      "years_dashboard",
      "dataExcelTotal"
    ]),
    ...mapState("sidebar", ["modules"]),

    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },

    //FF
    activarDescargaExcel() {
      if(this.range.date_start !== null && this.range.date_end !== null) return true;
      else return false;
    }
  },
  
  async created() {
    this.loading = true;
    this.getYear();

    const data_modules = {
      id_empresa: this.id_empresa,
      id_privilegio: this.$ls.get("user").privilegio[0].id_tipo,
      id_grupo: this.id_grupo
    };
    await this.getModules(data_modules);
    
    const data = JSON.parse(localStorage.getItem("vuejs__user"));
    if (data != null) {
      this.usuario = data.value;
    }
    
    const data_ = {
      id_empresa: this.id_empresa,
      fecha: this.selectedFecha
    };
    await this.getGeneralStatistics(data_);
    this.addStatistics();
    await this.getGraficoUsuariosSesion(data_);
    this.estadisticasUsabilidad = await this.getEstadisticasUsabilidad(data_);

    this.loading = false;
  },

  mounted() {
    this.setInitialDates();
  },

  methods: {
    ...mapActions("adminHomeModule", [
      "getGeneralStatistics",
      "getGraficoUsuariosSesion",
      "getDataForExcel",
      "getYearDashboard",
      "getDataForExcelTotal",
      "getEstadisticasUsabilidad"
    ]),
    ...mapActions("sidebar", ["getModules"]),

    sortedFirstModulesByViews() {
      return this.modules
        .filter(m => m.imagen_url)
        .sort((mA, mB) => +mB.visitas - +mA.visitas)
        .slice(0, 9);
    },

    // se debe agregar el control de que la variable se haga nula
    async getYear(){
      const resYear = await this.getYearDashboard({ id_empresa: this.id_empresa });
      if (resYear) this.selectedYear = this.years_dashboard[0];
      else this.selectedYear = this.nowChileMoment().format("YYYY");
    },

    dateFormat(date) {
      if (date != 'Inicio' && date != 'Término') {
        return moment(date).format("DD-MM-YYYY");
      } else {
        return date;
      }
    },

    async searchUsability() {
      const monthIdx = this.months.indexOf(this.selectedMonth);
      const fecha = moment({ year: this.selectedYear, month: monthIdx, day: 2 }).format("YYYY-MM-DD");

      const payload = {
        id_empresa: this.id_empresa,
        fecha: fecha
      };
      await this.getGeneralStatistics(payload);
      this.addStatistics();
      await this.getGraficoUsuariosSesion(payload);
      this.estadisticasUsabilidad = await this.getEstadisticasUsabilidad(payload);
    },

    /* TODO: un loading */
    async getDataExcel() {
      //let fields = [];
      let data_user_activos = [];
      let data_modulos_resumen = [];
      let data_noticias_vista = [];
      let data_noticias_likes = [];
      let data_galeria_vista = [];
      let data_galeria_likes = [];
      let data_video_vista = [];
      let data_video_likes = [];
      const workbook = utils.book_new();
      let data_ = {
        id_empresa: this.id_empresa,
        id_filtro: '3',
        fecha_inicio: this.range.mask_start,
        fecha_termino: this.range.mask_end
      };
      await this.getDataForExcelTotal(data_);
      try{
        // Generación de usuarios activos
        if(this.dataExcelTotal.login!=null){
              this.dataExcelTotal.login.forEach(response => {
              let rut_ = response.rut_usuario + '-' +response.dv_usuario;
              let excel_data = {
                Rut:rut_,
                Nombre: response.primer_nombre,
                Apellido: response.apellido_paterno,
                Cargo: response.cargo[0]!=null?response.cargo[0].nombre_cargo:"",
                Gerencia: response.gerencia[0]!=null?response.gerencia[0].nombre_gerencia:"",
                Sucursal: response.sucursal[0]!=null?response.sucursal[0].nombre_sucursal:"",
              }
              data_user_activos.push(excel_data)
          });
          const worksheet = utils.json_to_sheet(data_user_activos);
          utils.book_append_sheet(workbook, worksheet, "Usuarios activos");
        }
        // Generación de datos de modulo
        this.dataExcelTotal.modulos.forEach(response =>{
          let excel_modulos = {
            Modulo: response.nombre_modulo,
            Visitas: response.total_vistas,
            Likes: response.aplica_likes?response.total_likes : "N/A",
            Comentarios: response.aplica_comentarios? response.total_comentarios: "N/A"
          }
          data_modulos_resumen.push(excel_modulos);
        });
        const worksheet1 = utils.json_to_sheet(data_modulos_resumen);
        utils.book_new();
        utils.book_append_sheet(workbook, worksheet1, "Resumen visitas módulos");
        
        // Generación de noticias (vistas)
        if(this.dataExcelTotal.noticias.visto_noticia!=null){
            this.dataExcelTotal.noticias.visto_noticia.forEach(response =>{
              let excel_noticias_v = {
              Noticia: response.titulo_noticia,
              Visitas: response.visto_noticia,
            }
            data_noticias_vista.push(excel_noticias_v);
          });
          const worksheet2 = utils.json_to_sheet(data_noticias_vista);
          utils.book_new();
          utils.book_append_sheet(workbook, worksheet2, "Noticias más vistas");
        }
        // Generación de noticias total likes
        if(this.dataExcelTotal.noticias.likes_noticia!=null){
            this.dataExcelTotal.noticias.likes_noticia.forEach(response =>{
            let excel_noticias_l = {
              Noticia: response.titulo_noticia,
              Total_Likes: response.likes_noticia,
            }
            data_noticias_likes.push(excel_noticias_l);
          });
          const worksheet3 = utils.json_to_sheet(data_noticias_likes);
          utils.book_new();
          utils.book_append_sheet(workbook, worksheet3, "Noticias con más likes");
        }
        // Generación de galerias total (vistas) ranking
        if(this.dataExcelTotal.galerias.visto_galeria!=null){
            this.dataExcelTotal.galerias.visto_galeria.forEach(response =>{
            let excel_geleria_ran_visitas = {
              Galeria: response.titulo_galeria,
              Visitas: response.visto_galeria,
            }
            data_galeria_vista.push(excel_geleria_ran_visitas);
          });
          const worksheet4 = utils.json_to_sheet(data_galeria_vista);
          utils.book_new();
          utils.book_append_sheet(workbook, worksheet4, "Galerias más vistas");
        }
        // Generación de galerias total (like) ranking
        if(this.dataExcelTotal.galerias.likes_galeria!=null){
            this.dataExcelTotal.galerias.likes_galeria.forEach(response =>{
              let excel_geleria_ran_visitas = {
                Galeria: response.titulo_galeria,
                Total_Likes: response.likes_galeria,
              }
              data_galeria_likes.push(excel_geleria_ran_visitas);
            });
            const worksheet5 = utils.json_to_sheet(data_galeria_likes);
            utils.book_new();
            utils.book_append_sheet(workbook, worksheet5, "Galerias con más likes");
        }

        // Generación de videos Total Vistos (ranking)
        if(this.dataExcelTotal.videos.visto_video!=null){
              this.dataExcelTotal.videos.visto_video.forEach(response =>{
              let excel_geleria_ran_visitas = {
                Videos: response.titulo_video,
                Visitas: response.visto_video,
              }
              data_video_vista.push(excel_geleria_ran_visitas);
            });
            const worksheet6 = utils.json_to_sheet(data_video_vista);
            utils.book_new();
            utils.book_append_sheet(workbook, worksheet6, "Videos más vistos");
        }

        // Generación de videos Total likes (ranking)
        if(this.dataExcelTotal.videos!=null || this.dataExcelTotal.videos.likes_video!=null){
            this.dataExcelTotal.videos.likes_video.forEach(response =>{
              let excel_geleria_ran_like = {
                Videos: response.titulo_video,
                Total_Likes: response.likes_video,
              }
              data_video_likes.push(excel_geleria_ran_like);
            });
            const worksheet7 = utils.json_to_sheet(data_video_likes);
            utils.book_new();
            utils.book_append_sheet(workbook, worksheet7, "Videos con más likes");
        }

      
      }
      catch(err){
        console.log("Error Generado: "+err);
      }
      let nombre = "Reporte LK Fecha inicio "+this.dateFormat(this.range.mask_start)+" Fecha termino "+this.dateFormat(this.range.mask_end)+".xlsx";
      try{
        writeFile(workbook, nombre);
        this.$toast.open({
          message: "El proceso de descarga ha finalizado correctamente",
          type: "success",
          duration: 6000,
          position: "top-right",
        });
      }
      catch(err){
        this.$toast.open({
            message: "El proceso de descarga ha fallado, favor contactar a soporte@carpetres.cl",
            type: "error",
            duration: 0,
            position: "top-right",
        });
      }
    },
    addStatistics() {
      this.modules.forEach(mod => {
        const found_mod = this.generalStatistics.find(stat => stat.modulo.nombre_modulo == mod.nombre_modulo);
        if (found_mod) mod.visitas = found_mod.vistos;
      });
    },
    activeMonths() {
      const currentYear = this.nowChileMoment().format("YYYY");
      if (+this.selectedYear < +currentYear) {
        return this.months;
      } else if (this.selectedYear === currentYear) {
        const currentMonthIndex = this.nowChileMoment().month();
        return this.months.slice(0, currentMonthIndex + 1);
      }
    },

    setInitialDates() {
      this.selectedMonth = this.capitalizeFirstLetter(this.nowChileMoment().format("MMMM"));
      this.selectedFecha = this.nowChileMoment().format("YYYY-MM-DD");
    },

    //FF
    captureModulo(modulo) {
      this.modulo_actual = modulo;
    },
    captureDateStart(date) {
      this.range.date_start = date;
      this.range.mask_start = moment(date).format('YYYY-MM-DD');
    },
    captureDateEnd(date) {
      this.range.date_end = date;
      this.range.mask_end = moment(date).format('YYYY-MM-DD');
    }
  },
};
</script>
