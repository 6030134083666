<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";

//FF
import InputDate from "@/components/forms/InputDate.vue"

export default {
  props: {
    modulo: {
      type: Object,
      required: true
    },
  },
  components: {
    //FF
    InputDate
  },
  data() {
    return {
      filter: { id: "3", nombre: "Por periodo"},
      loading: true,
      range: {
        date_start: null,
        date_end: null,
        mask_start: null,
        mask_end: null
      }
    }
  },
  computed: {
    ...mapState("adminHomeModule", ["statisticsModule", "topNews", "topGallery", "topVideo", "filters"]),

    //FF
    filterEstadistica() {
      let estadisticas = [];
      if(Object.keys(this.statisticsModule).length !== 0){
        estadisticas = this.statisticsModule.estadisticas.filter(elemento => elemento.show === true);
        return estadisticas;
      }
      return estadisticas;
    }
  },
  methods: {
    ...mapActions("adminHomeModule", ["getStatisticsModule", "getTopNews", "getTopGallery", "getTopVideo"]),

    //FF
    captureDateStart(date) {
      this.range.date_start = date;
      this.range.mask_start = moment(date).format('YYYY-MM-DD');
    },
    captureDateEnd(date) {
      this.range.date_end = date;
      this.range.mask_end = moment(date).format('YYYY-MM-DD');
    },
    async captureDate() {
      this.range.date_start = moment(new Date()).subtract(1, 'month')._d;
      this.range.date_end = new Date();
      this.range.mask_start = moment(this.range.date_start).format('YYYY-MM-DD');
      this.range.mask_end = moment(new Date()).format('YYYY-MM-DD');
    },

    formatDate(date) {
      let format = moment(date).format('YYYY-MM-DD');
      return format;
    },

    async updateData(){
      const data = {
        idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
        idModulo: this.modulo.moduloGeneral.id_modulo,
        idFiltro: this.filter.id,
        fechaInicio: this.range.mask_start,
        fechaFin: this.range.mask_end,
      };
      try {
        await this.getStatisticsModule(data);
      } catch(error) {
        console.log(error);
      }
    },

    async getNews() {
      const data = {
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        id_filtro: this.filter.id,
        fecha_inicio: this.range.mask_start,
        fecha_fin: this.range.mask_end,
      };
      const resTopNews = await this.getTopNews(data);
      if (!resTopNews) {
        this.toastGenericError();
      }
    },
    
    async getGallery() {
      const data = {
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        id_filtro: this.filter.id,
        fecha_inicio: this.range.mask_start,
        fecha_fin: this.range.mask_end,
      };
      const resTopGallery = await this.getTopGallery(data);
      if (!resTopGallery) {
        this.toastGenericError();
      }
    },

    async getVideo() {
      const data = {
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        id_filtro: this.filter.id,
        fecha_inicio: this.range.mask_start,
        fecha_fin: this.range.mask_end,
      };
      const resTopVideo = await this.getTopVideo(data);
      if (!resTopVideo) {
        this.toastGenericError();
      }

    },
    handleLikeIconClass(likes) {
      if (likes > 0 && likes !== null) return true;
      else return false;
    },
  },
  async created() {
    await this.captureDate();
    await this.getNews();
    await this.getGallery();
    await this.getVideo();
  },
  watch: {
    modulo() {
      this.updateData();
    },
    "range.date_start"() {
      if(Object.keys(this.modulo).length !== 0){
        this.updateData();
        this.getNews();
        this.getGallery();
        this.getVideo();
      }
    },
    "range.date_end"() {
      if(Object.keys(this.modulo).length !== 0){
        this.updateData();
        this.getNews();
        this.getGallery();
        this.getVideo();
      }
    },
    "filter.id"() {
      this.updateData();
      this.getNews();
      this.getGallery();
      this.getVideo();
    },
  }
};
</script>
<template>
<div 
		class="modal fade" 
		id="modal-home" 
		tabindex="-1" 
		aria-hidden="true"
	>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title color-main font-700"> {{ modulo.nombre_modulo }} </h3>
        <button 
						type="button" 
						class="modal-custom-close"
						data-bs-dismiss="modal" 
						aria-label="Close">
						<font-awesome-icon icon="xmark"/>
					</button>
      </div>
      <div class="modal-body">
        <div class="row mb-3">
          <div 
            class="col-12 mb-3 mb-lg-0"
            :class="filter.id === '3' ? 'col-lg-5' : '' "
          >
            <v-select
              class="selvue-custom"
              v-model="filter"
              label="nombre"
              disabled
              :options="filters"
              :clearable="false">
            </v-select>   
          </div>
          <template v-if="filter.id === '3'">
            <div class="col-12 col-lg-7">
              <div class="row">
                <div class="col-6">
                  <InputDate 
                    :initialDate="range.date_start"
                    :max="range.date_end"
                    @dateEmit="captureDateStart"
                  />
                </div>
                <div class="col-6">
                  <InputDate 
                    :initialDate="range.date_end"
                    :min="range.date_start" 
                    @dateEmit="captureDateEnd"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
          <div class="row">
            <div 
              class="col-12"
              :class="modulo.nombre_modulo === 'Noticias' || modulo.nombre_modulo === 'Galería' || modulo.nombre_modulo ==='Videos' ? 'col-lg-5' : '' "
            >
            <h5 class="font-700 color-main mb-3">Interaciones</h5>
              <div class="row">
                <div 
                  v-for="item in filterEstadistica" 
                  :key="item.id"
                  :class="modulo.nombre_modulo === 'Noticias' || modulo.nombre_modulo === 'Galería' || modulo.nombre_modulo ==='Videos' ? 'col-12' : 'col-12 col-lg-4' ">
                  <div class="card border-0 card-shadow mb-3">
                    <div class="card-body">
                      <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                          <h1 class="mb-0 font-700 text-secondary">{{ item.total }}</h1>
                          <p class="mb-0 text-secondary text-opacity-50">{{ item.nombre }}</p>
                        </div>
                        <div class="flex-shrink-0 ms-3">
                          <img 
                            :src="item.url"
                            width="80"
                            height="80"
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="modulo.nombre_modulo === 'Noticias'">
              <div class="col-12 col-lg-7">
              <h5 class="font-700 color-main">Top 5 más vistas</h5>
              <div id="carouselNews" class="carousel slide">
                <div class="carousel-inner">
                  <div 
                    v-for="(item, index) in topNews" :key="item.id_noticia"
                    class="carousel-item"
                    :class="index === 0 ? 'active' : '' "
                  >
                    <article class="card card-item card-shadow border-round-10 border-0 m-2">
                      <figure class="card-item-figure">
                          <span class="card-item-date">
                            {{ formatDate(item.fecha_noticia) }}
                          </span>
                          <img 
                            :src="item.url_imagen_principal" 
                            class="card-item-figure-img"
                          >
                      </figure>
                      <div class="card-body card-item-excerpt">
                        <h5 class="card-item-title-2 font-main color-gray mb-0">
                          {{ item.titulo_noticia }}
                        </h5>
                      </div>
                    </article>
                  </div>
                </div>
                <template v-if="topNews.length !== 1">
                  <button 
                    class="carousel-control-prev fix-btn-carousel" 
                    type="button" 
                    data-bs-target="#carouselNews" 
                    data-bs-slide="prev"
                  >
                    <i class="fa-solid fa-angle-left color-main h1"></i>
                  </button>
                  <button 
                    class="carousel-control-next fix-btn-carousel" 
                    type="button" 
                    data-bs-target="#carouselNews" 
                    data-bs-slide="next"
                  >
                    <i class="fa-solid fa-angle-right color-main h1"></i>
                  </button>
                </template>
              </div>
              </div>
            </template>
            <template v-if="modulo.nombre_modulo === 'Galería'">
              <div class="col-12 col-lg-7">
                <h5 class="font-700 color-main">Top 5 más vistas</h5>

                <div id="carouselGallery" class="carousel slide">
                  <div class="carousel-inner">
                    <div 
                      v-for="(item, index) in topGallery" :key="item.id_galeria"
                      class="carousel-item"
                      :class="index === 0 ? 'active' : '' "
                    >
                      <article class="card card-item card-shadow border-round-10 border-0 m-2">
                        <figure class="card-item-figure">
                            <span class="card-item-date">
                              {{ formatDate(item.fecha_galeria) }}
                            </span>
                            <img 
                              :src="item.imagenes[0].url_imagen_original"
                              class="card-item-figure-img"
                            >
                        </figure>
                        <div class="card-body card-item-excerpt">
                          <h5 class="card-item-title-1 font-main color-gray">
                            <font-awesome-icon icon="images" class="color-main pe-1"/>
                            {{ item.titulo_galeria }}
                          </h5>
                          <div class="card-item-data">
                            <span>
                              <font-awesome-icon 
                                icon="image" 
                                class="color-secondary pe-1"
                              />
                              {{ item.imagenes.length }}
                              {{ item.imagenes.length > 1 ? "Fotos" : "Foto" }}
                            </span>
                            <span>
                              <i 
                              v-if="handleLikeIconClass(item.total_likes_admin)"
                              class="fa-solid fa-heart color-rose-shadow">
                            </i>
                            <i 
                              v-else
                              class="fa-regular fa-heart color-main">
                            </i>
                              {{ item.total_likes_admin == null ? 0 : item.total_likes_admin }}
                              Me gusta
                            </span>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                  <template v-if="topGallery.length !== 1">
                    <button 
                      class="carousel-control-prev fix-btn-carousel" 
                      type="button" 
                      data-bs-target="#carouselGallery" 
                      data-bs-slide="prev"
                    >
                      <i class="fa-solid fa-angle-left color-main h1"></i>
                    </button>
                    <button 
                      class="carousel-control-next fix-btn-carousel" 
                      type="button" 
                      data-bs-target="#carouselGallery" 
                      data-bs-slide="next"
                    >
                      <i class="fa-solid fa-angle-right color-main h1"></i>
                    </button>
                  </template>
                </div>
              </div>
            </template>
            <template v-if="modulo.nombre_modulo === 'Videos'">
              <div class="col-12 col-lg-7">
                <h5 class="font-700 color-main">Top 5 más vistas</h5>

                <div id="carouselGallery" class="carousel slide">
                  <div class="carousel-inner">
                    <div 
                      v-for="(item, index) in topVideo" :key="item.id_video"
                      class="carousel-item"
                      :class="index === 0 ? 'active' : '' "
                    >
                      <article class="card card-item card-shadow border-round-10 border-0 m-2">
                        <figure class="card-item-figure">
                            <span class="card-item-date">
                              {{ formatDate(item.fecha_video) }}
                            </span>
                            <img 
                              :src="item.url_imagen"
                              class="card-item-figure-img"
                            >
                        </figure>
                        <div class="card-body card-item-excerpt">
                          <h5 class="card-item-title-1 font-main color-gray">
                            <font-awesome-icon icon="images" class="color-main pe-1"/>
                            {{ item.titulo_video }}
                          </h5>
                          <div class="card-item-data">
                            <!-- <span>
                              <font-awesome-icon 
                                icon="image" 
                                class="color-secondary pe-1"
                              />
                              {{ item.imagenes.length }}
                              {{ item.imagenes.length > 1 ? "Fotos" : "Foto" }}
                            </span>-->
                            <span>
                              <i 
                              v-if="handleLikeIconClass(item.likes.length)"
                              class="fa-solid fa-heart color-rose-shadow">
                            </i>
                            <i 
                              v-else
                              class="fa-regular fa-heart color-main">
                            </i>
                              {{ item.likes == null ? 0 : item.likes.length}}
                              Me gusta
                            </span>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                  <template v-if="topVideo.length !== 1">
                    <button 
                      class="carousel-control-prev fix-btn-carousel" 
                      type="button" 
                      data-bs-target="#carouselGallery" 
                      data-bs-slide="prev"
                    >
                      <i class="fa-solid fa-angle-left color-main h1"></i>
                    </button>
                    <button 
                      class="carousel-control-next fix-btn-carousel" 
                      type="button" 
                      data-bs-target="#carouselGallery" 
                      data-bs-slide="next"
                    >
                      <i class="fa-solid fa-angle-right color-main h1"></i>
                    </button>
                  </template>
                </div>
              </div>
            </template>
          </div>
      </div>
    </div>
  </div>
</div>
</template>

<style lang="scss">

  .fix-btn-carousel {
    margin-bottom: 80px;
  }

</style>
